@font-face {
  font-family: 'Merriweather';
  src: local('Merriweather'),
    url('../fonts/Merriweather/Merriweather-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url('../fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url('../fonts/ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url('../fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: regular;
}

.error-message {
  padding: 4px 8px;
}
.error-message-title {
  font-weight: bold;
}
