@import "../../styles/variables.sass"

.App
  min-height: 100vh
  height: 100%
  overflow: hidden



  .App-content
    background: white


    @media (min-width: 813px)

  .warning
    background: $color-warning !important

  .danger
    background: $color-danger !important

  .padding-15px
    padding: 15px 15px 0 15px


  .no-padding
    padding: 0

  &-content__wrapper
    flex: 1

  &-content-footer__wrapper
    min-height: 100vh

.footer
  text-align: center
  margin-top: auto
  color: #a09e99
  font-size: 12px
  line-height: 12px
  padding: 18px 50px

.footer-white
  background-color: white
  border-top: 2px solid #a09e99

.footer-off
  display: none

.admin-role-column
  min-width: 120px

